import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cpm-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.scss']
})
export class ErrorHandlerComponent implements OnInit {
  isLoggedIn = false;
  email = '';
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.email = this.authService.getUserEmail();
    this.isLoggedIn = !!this.email;
  }

  signOut() {
    this.authService.signOut();
  }

  openHomePage() {
    this.router.navigate(['/cpm']).then();
  }
}
