<div class="header">
  <div class="info">
    <div class="platform-name">Sensinel ™ CPM System</div>
    <div class="terms-of-use">Terms of Use</div>
  </div>
  <mat-icon *ngIf="onClose" (click)="onClose()" class="close" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="content-wrapper" [class.with-bottom-shadow]="!isTriggerElemIntersecting"
  [class.with-no-actions]="!isAcceptAllowed">
  <div class="content-container">
    <div class="content">
      <mat-spinner *ngIf="isLoading" diameter="32"></mat-spinner>
      <div [innerHTML]="tncData?.tncText"></div>
      <div class="trigger" #trigger></div>
    </div>
  </div>
</div>
<mat-checkbox *ngIf="isAcceptAllowed" [(ngModel)]="isAgreed"
  [disabled]="!isContentBottomReached  || tncData?.isTncAccepted==='Y' || isSubmitLoading" class="agree-checkbox"
  [matTooltip]="!isContentBottomReached ? 'Scroll to read all of the Terms of Use to agree.':null"
  matTooltipPosition="below" matTooltipClass="tnc-tooltip">
  I agree to the Terms of Use.
</mat-checkbox>
<button *ngIf="isAcceptAllowed" [disabled]="!isAgreed || tncData?.isTncAccepted==='Y' || isSubmitLoading"
  (click)="onContinueClick()" type="button" mat-flat-button>Continue
</button>
