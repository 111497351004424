import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({ name: 'dateParseWithTimezone' })
export class DateParseWithTimezonePipe implements PipeTransform {
  transform(value: any, tz: string): any {
    if (value) {
      return moment.tz(value, tz);
    }
  }
}
