<header class="header">
  <div class="container-fluid">
    <div class="main-nav row">
      <div class="col-auto">
        <img alt="Logo" class="logo" src="/assets/sensinel-cpm-system-logo.svg">
      </div>
    </div>
  </div>
</header>
<div class="container">
  <cpm-terms-and-conditions [isAcceptAllowed]="true"></cpm-terms-and-conditions>
</div>
