export const english = {
  'CPM Analytics': 'Sensinel Analytics',
  CPM_CONFIRM_LEAVE_TITLE: 'Are you sure you want to leave this page?',
  CPM_CONFIRM_LEAVE_CONTENT:
    'You are navigating away from this patient record page.',
  CPM_CONFIRM_LEAVE_BTN: 'YES, LEAVE PAGE',
  CPM_CONFIRM_LEAVE_CANCEL_BTN: 'CANCEL',
  PATIENT_DETAILS_TITLE: 'Patient Details',
  HISTORY_TITLE: 'History',
  NAME_LABEL: 'Last name, First name',
  DOB_LABEL: 'Date of Birth (mm-dd-yyyy)',
  SEX_LABEL: 'Sex',
  TELEPHONE_NUMBER_lABEL: "Patient's Telephone Number",
  ENROLLED_LABEL: 'Enrolled on',
  DOCTOR_NAME: "Patient's Doctor's Name",
  COMORBIDITIES_TITLE: 'Comorbidities',
  MEDICATIONS_TITLE: 'Medications',
  PROCEDURES_TITLE: 'Procedures',
  IMPLANTS_TITLE: 'Implants',
  NONE_LABEL: 'None',
  CPM_PATIENT_CONFIRMED_LABEL: 'Patient Confirmed',
  SYNC_WITH_EMR_BUTTON: 'Check With EMR',
  CPM_MEASUREMENT_VALUE_TITLE: 'Measurement Parameter',
  CPM_MEASUREMENT_PARAM_TITLE: 'Measurement Parameter',
  CPM_LAST_TITLE: 'Last',
  CPM_14D_ANALYTICS_TITLE: '14d Analytics',
  CPM_3D_ANALYTICS_TITLE: '3d Analytics',
  CPM_3D_ANALYTICS_CHANGE_TITLE: 'Change',
  CPM_3D_ANALYTICS_PERCENTAGE_CHANGE_TITLE: '% Change',
  CPM_3D_ANALYTICS_MEAN_TITLE: 'Mean',
  CPM_14D_ANALYTICS_RANGE_TITLE: 'Range',
  CPM_14D_ANALYTICS_CHANGE_TITLE: '% Change',
  CPM_LAST_READING_TITLE: 'Value',
  CPM_CHANGE_LAST_READING_TITLE: '% Change Trend<br>Last 6 Readings',
  CPM_NA_PARAMETER_INFO: '*N/A - Data and trends only shown where applicable',
  CPM_READING_HOVER_INFO: '(Hover/click for more details)',
  CPM_TOOLTIP_TREND_CALCULATION_TEXT:
    '*Trend is calculated using the parameter’s recorded values from the last 6 readings. Where the last 1-3 reading values (Group 1) and the last 4-6 reading values (Group 2) are averaged, respectively and compared, to obtain the trend.',
  CPM_TOOLTIP_HEART_TREND_WIDGET:
    '3-day actionable insights show the actual value change over the last three days and the average measurement computed over the last three days for the given parameter.<br><br>14-day actionable insights show the range of 4-day average measurements during the last fourteen days and the overall percent change in the measurements during that time for the given parameter.<br><br>The latest measurement value for each parameter is shown in the "Last Value" column.<br><br>Asterisks indicate calculation is not applicable for that parameter.',
  CPM_TOOLTIP_LUNGS_TREND_WIDGET:
    '3-day analytics include the percent change over the last three days and the average measurement computed over the last three days for the given parameter.<br><br>14-day analytics include the range of 4-day average measurements during the last 14 days and the overall percent change in the measurements over the last 14 days for the given parameter.<br><br>The latest measurement value for each parameter is shown in the "Last Value" column.<br><br>Asterisks indicate that a given calculation is not applicable for that parameter.',
  CPM_PERCENTAGE_CHANGE_LABEL: '% Change Trend (Last 6 Readings)',
  CPM_PARAMETER_TRENDING_LABEL: 'Parameter is trending (Last 6 readings)*',
  CPM_LAST_READIG_VALUE_LABEL: 'Last Reading value',
  CPM_COMPLIANCE_TITLE: 'Compliance',
  CPM_READING_ACTIVITY_TITLE: 'Reading Activity',
  CPM_ANALYTICS_TITLE: 'CPM Actionable Insights and Notifications',
  CPM_HEART_RATE_LABEL: 'Heart Rate',
  CPM_QRS_WIDTH_LABEL: 'QRS Width',
  CPM_QT_WIDTH_LABEL: 'QT Interval',
  CPM_QTC_WIDTH_LABEL: 'QTc Interval',
  CPM_RESP_RATE_LABEL: 'Respiration Rate',
  CPM_READING_DAYS_MISSED: 'Days Received / Days Missed',
  CPM_HEART_NO_RECORD_TITLE:
    'There is no reported trend data available for HR at this time',
  CPM_QRS_NO_RECORD_TITLE:
    'There is no reported trend data available for QRS at this time',
  CPM_QTC_NO_RECORD_TITLE:
    'There is no reported trend data available for QTc at this time',
  CPM_RESP_RATE_NO_RECORD_TITLE:
    'There is no reported trend data available for RR at this time',
  CPM_ECG_NO_ABNORMALITY_TITLE:
    "There is no reported data available for the patient's ECG Waveform Pattern since their last reading.",
  CPM_ECG_NO_RECORD_TITLE:
    'There is no reading data available yet for this patient.',
  CPM_ECG_WAVE_TITLE:
    'Potential ECG abnormalities (irregularly irregular rhythm or missing p-wave). Please review the ECG data and any other relevant information.',
  CPM_S3_NO_RECORD_TITLE:
    'There is no reported trend data available for Diastolic Heart Sounds Trend at this time',
  CPM_TIDAL_NO_RECORD_TITLE:
    'There is no reported trend data available for Tidal Volume at this time',
  CPM_THORACIC_NO_RECORD_TITLE:
    'There is no reported trend data available for Thoracic Impedance at this time',
  CPM_RR_RTV_NO_RECORD_TITLE:
    'There is no reported trend data available for RR/rTV at this time',
  CPM_READING_NO_RECORD_TITLE:
    'There is no reported data available for Reading Activity at this time',
  CPM_THRESHOLD_NO_RECORD_TITLE:
    'There is no reported data available for Threshold at this time',
  CPM_QRS_RIT_TITLE:
    "The QRS reported data indicates that there has been little to no change in the trend data reported since the patient's last reading",
  CPM_QTC_RIT_TITLE:
    "The QTc reported data indicates that there has been little to no change in the trend data reported since the patient's last reading",
  CPM_QRS_UP_TITLE: 'The QRS has increased by ',
  CPM_QRS_DW_TITLE: 'The QRS has decreased by ',
  CPM_QTC_UP_TITLE: 'The QTc has increased by ',
  CPM_QTC_DW_TITLE: 'The QTc has decreased by ',
  CPM_SINCE_LAST_READING_TITLE: "% since the patient's last reading.",
  CPM_HEART_UP_TITLE: 'The HR has increased by ',
  CPM_HEART_DW_TITLE: 'The HR has decreased by ',
  CPM_HEART_RIT_TITLE:
    "The HR reported data indicates that there has been little to no change in the trend data reported since the patient's last reading",
  CPM_RESP_UP_TITLE: 'The RR has increased by ',
  CPM_RESP_DW_TITLE: 'The RR has decreased by ',
  CPM_RESP_RIT_TITLE:
    "The RR reported data indicates that there has been little to no change in the trend data reported since the patient's last reading",
  CPM_TOTAL_THRESHOLD_BREACHES_LABEL: 'Readings with Threshold Breaches',
  CPM_TOTAL_THRESHOLD_BREACHES_REPORTED_LABEL:
    'Breaches Reported -- Review in Trends!',
  CPM_TOTAL_THRESHOLD_ABRTION_REPORTED_LABEL:
    'An Atrial Fibrillation result means the heart is beating in an irregular pattern.',
  CPM_READING_ON_TRACK: 'On-track',
  CPM_READING_SOMEWHAT_ON_TRACK: 'Somewhat on-track',
  CPM_READING_NOT_ON_TRACK: 'Not on-track',
  CPM_READING_NOT_WITHIN_PROTOCOL: 'Not within protocol',
  CPM_READING_NO_READING_ACTIVITY: 'No Reading Activity',
  CPM_VIEW_DETAILS_LABEL: 'view details',
  CPM_S3_LABEL: 'Diastolic Heart Sounds Strength',
  CPM_ECG_WAVEFORM_PATTERN_TEXT: 'ECG Waveform Pattern',
  CPM_AFIB_NO_MEASUREMENTS_DETECTED_HEADER_TEXT: 'No readings detected yet',
  CPM_AFIB_NO_MEASUREMENTS_TOOLTIP_TEXT: '',
  CPM_AFIB_INCONCLUSIVE_HEADER_TEXT: 'Inconclusive Readings',
  CPM_AFIB_INCONCLUSIVE_TOOLTIP_TEXT:
    'An inconclusive result means the recording can’t be classified. Possible reasons for an inconclusive result include: Moving too much or too much noise during the recording; The recording is too short; The wearable is not properly adhered.',
  CPM_AFIB_DETECTED_HEADER_TEXT: 'Readings with possible atrial fibrillation',
  CPM_AFIB_DETECTED_TOOLTIP_TEXT:
    'An Atrial Fibrillation result means the heart is beating in an irregular pattern.',
  CPM_NO_IRREGULAR_RHYTHM_HEADER_TEXT:
    'No signs of irregular rhythm could be detected by the Sensinel™ CPM Intelligent Algorithm',
  CPM_NO_IRREGULAR_RHYTHM_TOOLTIP_TEXT:
    'No signs of atrial fibrillation were detected.',
  CPM_READING_ACTIVITY_TEXT_FIRST: '(Last',
  CPM_READING_ACTIVITY_TEXT_SECOND: ' Days)',
  CPM_ANALYTICS_NOTIFICATION_TITLE: '(Last 14 days)',
  CPM_THRESHOLD_NOTIFICATION_TEXT: 'Threshold Notifications',
  CPM_NOT_LOGGED_IN: 'No current user',
  CPM_S3_UP_TITLE:
    'The recent patient reading indicates an upward Diastolic Heart Sounds Trend change in value since the last reading',
  CPM_S3_DW_TITLE:
    'The recent patient reading indicates a downward Diastolic Heart Sounds Trend change in value since the last reading',
  CPM_S3_FLT_TITLE:
    'No change, neither increase or decrease, and the measurement is the same as the last reading',
  CPM_TIDAL_LABEL: 'Relative Tidal Volume',
  CPM_TIDAL_UP_TITLE:
    'The recent patient reading indicates an upward Tidal Volume change in value since the last reading',
  CPM_TIDAL_DW_TITLE:
    'The recent patient reading indicates a downward Tidal Volume change in value since the last reading',
  CPM_TIDAL_FLT_TITLE:
    'No change, neither increase or decrease, and the measurement is the same as the last reading',
  CPM_THORACIC_LABEL: 'Thoracic Impedance',
  CPM_THORACIC_UP_TITLE:
    'The recent patient reading indicates an upward Thoracic Impedance change in value since the last reading',
  CPM_THORACIC_DW_TITLE:
    'The recent patient reading indicates a downward Thoracic Impedance change in value since the last reading',
  CPM_THORACIC_FLT_TITLE:
    'No change, neither increase or decrease, and the measurement is the same as the last reading',
  CPM_RR_RTV_LABEL: 'RR/rTV',
  CPM_RR_RTV_UP_TITLE:
    'The recent patient reading indicates an upward RR/rTV change in value since the last reading',
  CPM_RR_RTV_DW_TITLE:
    'The recent patient reading indicates a downward RR/rTV change in value since the last reading',
  CPM_RR_RTV_FLT_TITLE:
    'No change, neither increase or decrease, and the measurement is the same as the last reading',
  CPM_READ_LABEL: 'Reading Compliance',
  CPM_READ_COMPLIANCE_DAYS_NOT_MATCH: 'Compliance period is incorrect',
  CPM_READ_COMP_TITLE: 'Click for more details',
  CPM_READ_LAST_DAYS: 'Last',
  CPM_READ_DAYS: 'days reading activity:',
  CPM_READ_RECEIVED: 'Received',
  CPM_READ_NOT_RECEIVED: 'Not Received',
  CPM_READ_PARTIAL: 'Partial Data',
  CPM_READ_FUTURE: 'Future Day',
  CPM_READ_NO_DELAY_LABEL:
    'The patient has reported daily measures and is compliant in the program',
  CPM_READ_ONE_DAY_DELAY_LABEL:
    'The patient has missed one day and is non-compliant in the program',
  CPM_READ_TWO_DAY_DELAY_LABEL:
    'The patient has missed two days and is non-compliant in the program',
  CPM_READ_THREE_DAY_DELAY_LABEL:
    'The patient has missed three days and is non-compliant in the program',
  CPM_THRESHOLD_BREACH_LABEL:
    'Threshold has been breached in the current (last) reading received from the patient',
  CPM_THRESHOLD_NONE_LABEL: 'No threshold set',
  CPM_THRESHOLD_SET_LABEL: 'Thresholds have been set',
  CPM_THRESHOLD_ALARM_LABEL: 'Threshold Alarm',
  CPM_PATIENT_COMP_MSG: 'This patient is compliant and has missed ',
  CPM_PATIENT_SOME_WHAT_COMP_MSG:
    'This patient is somewhat compliant and has missed ',
  CPM_PATIENT_NOT_VERY_COMP_MSG:
    'This patient is not very compliant and has missed ',
  CPM_PATIENT_NOT_COMP_MSG: 'This patient is not compliant and has missed ',
  CPM_PATIENT_READING_COMP_F_MSG: ' readings over the last ',
  CPM_PATIENT_READING_COMP_L_MSG: ' days',
  READING_COMPLIANCE_POPUP_TITLE: 'Reading Compliance',
  READING_ACTIVITY_POPUP_TITLE: 'Reading Activity',
  READING_COMPLIANCE_POPUP_READING_RECEIVED: 'Reading received',
  READING_COMPLIANCE_POPUP_READING_NOT_RECEIVED: 'Reading NOT received',
  READING_COMPLIANCE_POPUP_MISSED_READING_FIRST_LABEL: 'Patient',
  READING_COMPLIANCE_POPUP_MISSED_READING_SECOND_LABEL: 'missed',
  READING_COMPLIANCE_POPUP_MISSED_READING_THIRD_LABEL: 'days of the last',
  READING_COMPLIANCE_POPUP_MISSED_READING_FOURTH_LABEL: 'days',
  THRESHOLD_RELATIVE_CHANGE_ERR_MSG: 'Percentage value must be',
  THRESHOLD_RANGE_ERR_MSG: 'Value is required within range',
  THRESHOLD_AND_ERR_MSG: 'and',
  THRESHOLD_MIN_ERR_MSG: 'Min value must be',
  THRESHOLD_MAX_ERR_MSG: 'Max value must be',
  THRESHOLD_MIN_LESS_THAN_ERR_MSG: 'Min value must be < max value',
  THRESHOLD_MAX_GREATER_THAN_ERR_MSG: 'Max value must be > min value',
  THRESHOLD_MIN_INPUT_LABEL: 'Min',
  THRESHOLD_MAX_INPUT_LABEL: 'Max',
  THRESHOLD_NOT_APPLICABLE_MSG: '(Not applicable)',
  THRESHOLD_RELATIVE_CHANGE_PERCENT_LABEL: 'Relative Change %',
  THRESHOLD_RELATIVE_INCREASE_PERCENT_LABEL: 'Increase %',
  THRESHOLD_RELATIVE_DECREASE_PERCENT_LABEL: 'Decrease %',
  THRESHOLD_ABSOLUTE_LABEL: 'Absolute Thresholds (unit)',
  THRESHOLD_BASELINE_LABEL: 'Baseline Thresholds (%)',
  THRESHOLD_RESTORE_DEFAULT_BTN: 'Restore Defaults',
  THRESHOLD_SAVE_BTN: 'Save',
  THRESHOLD_CANCEL_BTN: 'Cancel',
  THRESHOLD_PATIENT_TOTAL: 'Thresholds Set ',
  THRESHOLD_MASTER_LIST_TOTAL: ' of ',
  MANAGE_THRESHOLD_SETTINGS_BTN: 'Manage Threshold Settings',
  THRESHOLD_UPDATED_SUCCESS_MSG: 'Threshold is updated successfully',
  BP_THRESHOLD_TOOLTIP_TITTLE: 'BP Tooltip TITLE',
  BP_THRESHOLD_TOOLTIP_HEADER: 'BP Tooltip HEADER',
  BP_THRESHOLD_TOOLTIP_CONTENT: 'BP Tooltip CONTENT',
  ECG_THRESHOLD_TOOLTIP_TITTLE: 'ECG Tooltip TITLE',
  ECG_THRESHOLD_TOOLTIP_HEADER: 'ECG Tooltip HEADER',
  ECG_THRESHOLD_TOOLTIP_CONTENT: 'ECG Tooltip CONTENT',
  HR_THRESHOLD_TOOLTIP_TITTLE: 'Heart Rate',
  HR_THRESHOLD_TOOLTIP_HEADER: '',
  HR_THRESHOLD_TOOLTIP_CONTENT:
    'A normal resting heart rate for adults ranges from 60 to 100 beats per minute',
  QRS_THRESHOLD_TOOLTIP_TITTLE: 'QRS Width',
  QRS_THRESHOLD_TOOLTIP_HEADER: '',
  QRS_THRESHOLD_TOOLTIP_CONTENT:
    'In the electrocardiogram, the interval that denotes depolarization of the ventricles, between the beginning of the Q wave and the end of the S wave. Its normal duration is 120–200 msec. Shorter durations are found, e.g., in pre-excitation. Longer durations are indicative of first-degree heart block.',
  QT_THRESHOLD_TOOLTIP_TITTLE: 'QTc Interval',
  QT_THRESHOLD_TOOLTIP_HEADER: '',
  QT_THRESHOLD_TOOLTIP_CONTENT:
    "In electrocardiography, the duration of the QT interval adjusted for the patient's heart rate. Definitions of normal QTc vary from being equal to or less than 0.40 s (≤ 400 ms), 0.41 s (≤ 410 ms), 0.42 s (≤ 420 ms) or 0.44 s (≤ 440 ms).",
  QTC_THRESHOLD_TOOLTIP_TITTLE: 'QTc Interval',
  QTC_THRESHOLD_TOOLTIP_HEADER: '',
  QTC_THRESHOLD_TOOLTIP_CONTENT:
    "In electrocardiography, the duration of the QT interval adjusted for the patient's heart rate. Definitions of normal QTc vary from being equal to or less than 0.40 s (≤ 400 ms), 0.41 s (≤ 410 ms), 0.42 s (≤ 420 ms) or 0.44 s (≤ 440 ms).",
  RR_THRESHOLD_TOOLTIP_TITTLE: 'About Respiration Rate',
  RR_THRESHOLD_TOOLTIP_HEADER: ' ',
  RR_THRESHOLD_TOOLTIP_CONTENT:
    'The number of inhalations and exhalations per unit of time, usually measured by observation of chest movements and averaging 16 to 20 per minute in an adult. ',
  S3_THRESHOLD_TOOLTIP_TITTLE: 'Diastolic Heart Sounds',
  S3_THRESHOLD_TOOLTIP_HEADER: '',
  S3_THRESHOLD_TOOLTIP_CONTENT:
    'Diastolic Heart Sounds Strength represents the energy of heart sounds during diastole as measured by the Sensinel™ CPM Web App in the default lowest-angle posture. In healthy subjects, this value should be stable across days, while increasing values can indicate an increase in diastolic sounds. These values are not displayed in standard units and may vary among individuals and depending on sensor placement.',
  TI_THRESHOLD_TOOLTIP_TITTLE: 'Thoracic Impedance',
  TI_THRESHOLD_TOOLTIP_HEADER: '',
  TI_THRESHOLD_TOOLTIP_CONTENT:
    'A measure of the electrical activity in the chest that varies with changes in body size and composition, fluid volume, ventilatory status, and other variables.',
  TV_THRESHOLD_TOOLTIP_TITTLE: 'Relative Tidal Volume',
  TV_THRESHOLD_TOOLTIP_HEADER: '',
  TV_THRESHOLD_TOOLTIP_CONTENT:
    'Relative Tidal volume (symbol VT or TV) is the lung volume representing the normal volume of air displaced between normal inhalation and exhalation when extra effort is not applied. In a healthy, young human adult, tidal volume is approximately 500 mL per inspiration or 7 mL/kg of body mass.',
  CLEARED_BREACH_TOOLTIP_TITTLE: 'Breach notifications last cleared by',
  CLEARED_BREACH_TOOLTIP_HEADER: '',
  CLEARED_BREACH_TOOLTIP_CONTENT:
    'Indicates the name of the last person to review and clear the breached parameter notification from the legend and when. Breach nototfications ae cleared from the legend only. Breach parameter notitfications remain viewable on the trend chart for the selected parameters. You may also view the history of cleared breaches using the link provided below. The system maintains a history of who cleared and reviewed breaches and when.',
  CLEAR_BREACH_NOTIFICATION_LABEL: 'Clear breach notifications',
  CPM_CLEAR_BREACH_NOTIFICATION_AUDIT_LABEL: 'View cleared history',
  CPM_CLEAR_BREACH_NOTIFICATION_TITLE: 'Clear All Breach Notifications',
  CPM_CLEAR_BREACH_NOTIFICATION_INFO:
    'Clearing the breach notifications will remove the notification icons from the legend display only for this session.',
  CPM_CLEAR_BREACH_NOTIFICATION_CONTENT:
    'The next time you login to view this patient record, new breach notification icons will appear for any new data that has been processed for this patient since the last time you logged in.',
  CPM_CLEAR_BREACH_NOTIFICATION_BTN: 'Yes, clear all breach notifications',
  CPM_CLEAR_BREACH_NOTIFICATION_CANCEL_BTN: 'No, Cancel',
  CPM_CLEAR_BREACH_NOTIFICATION_AUDIT_TITLE:
    'Clear Breach Notifications History',
  CPM_BREACH_AUDIT_OK_BTN: 'OK',
  CPM_PARAMETER_BREACH_LABEL: 'Parameter breached a set threshold',
  MANAGE_THRESHOLD_SETTINGS_POPUP_TITLE: 'Manage Threshold Settings',
  CPM_CAUTION_LABEL: 'CAUTION: ',
  CPM_CAUTION_TEXT:
    'This patient history data was entered during enrollment and updated during the initial CPM System setup and baseline visit. This data may not represent the current clinical status of the patient.',
  CPM_WARNING_LABEL: 'WARNING: ',
  CPM_EMR_WARNING_TEXT:
    'Medical professionals should always refer to and use EMR data first as their source of record for patient information.',
  MANAGE_THRESHOLD_NOTE:
    'Please consult with a licensed medical professional before modifying the customized thresholds for this patient',
  THRESHOLD_MEASUREMENT_PARAMETER_NAME_LABEL: 'Measurement Parameter Name',
  THRESHOLD_MEASUREMENT_PARAMETER_VALUE_LABEL: 'Measurement Parameter Value',
  THRESHOLD_UPDATED_BY_LABEL: 'Thresholds last updated/modified by: ',
  THRESHOLD_UPDATED_ON_LABEL: 'on',
  TREND_SELECT_PARAMETER_LABEL: '( Select parameter to view in trends )',
  TREND_POSITION_LABEL:
    'Measurement values are shown for the most-reclined body posture (min tilt angle) only',
  TREND_AVG_TREND_LABEL: 'Avg 3 reading trend',
  TREND_READING_VALUE_LABEL: 'Reading value',
  TREND_STV_LABEL: 'Relative Tidal Volume (ohm)',
  SKIN_TEMP_LABLE: 'Temperature reading',
  SKIN_TEMP_IN_RANGE_LABLE: 'Temperature in range',
  SKIN_TEMP_NOT_IN_RANGE_LABLE: 'Temperature not in range',
  TREND_SRR_LABEL: 'Respiration Rate (bpm)',
  TREND_SRRTV_LABEL: 'RR/rTV',
  TREND_FTV_LABEL: 'Relative Tidal Volume (ohm)',
  TREND_FRR_LABEL: 'Respiration Rate (bpm)',
  TREND_FRRTV_LABEL: 'RR/rTV',
  TREND_DZ_LABEL: 'Delta Z (ohm)',
  TREND_S3E_LABEL: 'Diastolic Heart Sounds Strength (units)',
  TREND_TI_LABEL: 'Thoracic Impedance (ohm)',
  TREND_HR_LABEL: 'Heart Rate (bpm)',
  TREND_QTC_LABEL: 'QTc Interval (ms)',
  TREND_QRS_LABEL: 'QRS Width (ms)',
  TREND_ANGLE1_LABEL: 'Body Posture / Tilt Angle',
  TREND_ANGLE2_LABEL: 'Body Tilt Angle',
  TREND_BS_LABEL: 'Baseline',
  TREND_XAXIS_LABEL: 'Date/Time',
  PATIENT_DETAILS_NO_READING_FOUND:
    'No data yet, check back here after taking a reading.',
  PATIENT_VIEW_TREND_TITLE: 'Trends for selected parameters',
  PATIENT_SELECTED_PARAMETERS_TITLE: 'Selected Parameters',
  MANAGE_READINGS_BTN: 'Load more ECG Readings',
  PATIENT_VIEW_ECG_TITLE: 'ECGs for selected available readings',
  PATIENT_VIEW_CLOSE_RECORD_BTN: 'Close Record',
  PATIENT_VIEW_TREND_ECG_TAB_TITLE: 'Trends and ECGs',
  PATIENT_VIEW_MORE_CHART_TAB_TITLE: 'Parameter Detail Charts',
  POSITION_MIN_ANGLE_LABEL: 'Position (Min Angle)',
  POSITION_MAX_ANGLE_LABEL: 'Position (Max Angle)',
  THORACIC_IMPEDANCE_CHART_TITLE: 'Thoracic Impedance (ohm)',
  HEART_RATE_CHART_TITLE: 'Heart Rate (bpm)',
  S3_ENERGY_CHART_TITLE: 'Diastolic Heart Sounds Strength (units)',
  DELTA_Z_CHART_TITLE: 'Delta Z (ohm)',
  RR_TV_CHART_TITLE: 'RR/rTV',
  RESPIRATION_CHART_TITLE: 'Respiration Rate (bpm)',
  TIDAL_VOLUME_CHART_TITLE: 'Relative Tidal Volume (ohm)',
  QRS_CHART_TITLE: 'QRS Width (ms)',
  QTC_CHART_TITLE: 'QTc Interval (ms)',
  BODY_ANGLE_CHART_TITLE: 'Body Posture / Tilt Angle',
  AVAILABLE_READING_TITLE: 'Manage Readings & Select Baseline',
  ANOMALY_AVAILABLE_READING_TITLE: 'Available Readings',
  DASHBOARD_ENTER_PATIENT_ID: 'Patient Search',
  DASHBOARD_PATIENT_RECORD:
    'Enter a patient’s name or Patient ID in the field below.',
  NEED_HELP_MENU: 'Need Help',
  RESET_BREACH_CLEAR_BTN_TEXT: 'Clear Counts',
  RESET_BREACH_SUCCESS_MSG:
    'All threshold counts and notifications cleared successfully',
  RESET_BREACH_ERROR_MSG:
    'Failed to clear all threshold counts and notifications. Try again',
  RESET_BREACH_DIALOG_TITLE: 'Clear All Threshold Counts and Notification',
  RESET_BREACH_DIALOG_CONTENT:
    'Clearing all of the threshold counts will remove all of the threshold breach counts as well as the threshold notifications for all readings for this patient record. Are you sure you wish to clear all the threshold counts and notifications?',
  RESET_BREACH_DIALOG_CLEAR_BTN_TEXT:
    'Clear all threshold counts and notifications',
  RESET_BREACH_DIALOG_CANCEL_BTN: 'Cancel',
  VITAL_RECORDS_TITLE: 'Vital Records',
  ADD_VITAL_RECORD_BTN_TEXT: '+Add Vital Record',
  VITAL_CREATED_ON_TEXT: 'Vital taken on',
  SYSTOLIC_MMHG_TEXT: 'Systolic mmHg',
  DIASTOLIC_MMHG_TEXT: 'Diastolic mmHg',
  WEIGHT_LBS_KG_TEXT: 'Weight lbs/Kg',
  SKIN_TEMPERATURE_GRAPH_TITLE: 'Skin Temperature Trend',
  SKIN_TEMPERATURE_GRAPH_SUB_TITLE: 'Patient Readings-Last 30 Days',
  CELSIUS_LABEL: '°C',
  FAHRENHEIT_LABEL: '°F',
  FAHRENHEIT_TEXT: 'Fahrenheit',
  CELSIUS_TEXT: 'Celsius',
  SKIN_TEMPERATURE_TEXT: 'Temperature',
  SKIN_CORE_TEMPERATURE_TEXT: 'Skin/Core Temperature',
  SKIN_XAXIS_LABEL: 'Day',
  SKIN_GRAPH_LEGEND: 'No data available for day',
  SKIN_NO_DATA: 'No data available',
  CUSTOM_VITALS_TEXT: 'Custom Vitals',
  ADDITIONALCHART_XAXIS_LABEL: 'Date/Time',
  PATIENT_LIST_PATIENT_NOT_FOUND:
    'Unable to locate patient record or permission denied',
  PATIENT_SEARCH_NOT_AUTHORIZED:
    'Unable to locate patient record or permission denied',
  UNAUTHORIZED_OPERATION: 'Not authorized to perform this action',
  INTERNAL_SERVER_ERROR: 'Something went wrong. Please try again later',
  NEW_VITALS_RECORD_TITLE: 'New Vitals Record',
  EDIT_VITALS_RECORD_TITLE: 'Edit Vitals Record',
  NEW_VITAL_RECORD_DETAIL:
    'For each of the fields below add the current vital details for the patient.',
  EDIT_VITAL_VERSION_HISTORY_LABEL: 'Version History',
  EDIT_VITAL_VERSION_HINT_LABEL: '( record was last edited and saved by user )',
  EDIT_VITAL_SELECT_TO_RESTORE_LABEL: 'Select one to restore',
  DATE_VITAL_TAKEN_LABEL: 'Date Vital Taken',
  RECORDED_TEMPERATURE_LABEL: 'Recorded Skin Temperature',
  CORE_TEMPERATURE_LABEL: 'Core Temperature',
  RECORDED_TEMPERATURE_ERR_MSG:
    'Value must be a floating integer (e.g, xxx or xxx.xx)',
  SYSTOLIC_BLOOD_PRESSURE_LABEL: 'Systolic Blood Pressure',
  BP_RANGE_ERR_MSG: 'Values must be from 40 to 250',
  SYSTOLIC_BP_BLANK_ERR_MSG: 'Please enter Systolic Blood Pressure Value',
  SYSTOLIC_BP_GREATER_THAN_DIASTOLIC_BP_ERR_MSG:
    'Systolic Blood Pressure Value should be greater than Diastolic Blood Pressure',
  WEIGHT_LABEL: 'Weight',
  ERROR_VALUES_MUST_BE_GREATER_THAN_1: 'Values must be greater than 1',
  INVALID_WEIGHT_ERR_MSG:
    'Value must be a floating integer (e.g, xxx or xxx.xx)',
  KG_LABEL: 'kg',
  LBS_LABEL: 'lbs',
  DIASTOLIC_BLOOD_PRESSURE_LABEL: 'Diastolic Blood Pressure',
  DIASTOLIC_BP_BLANK_ERR_MSG: 'Please enter Diastolic Blood Pressure Value',
  DIASTOLIC_BP_LESS_THAN_SYSTOLIC_BP_ERR_MSG:
    'Diastolic Blood Pressure Value should be less than Systolic Blood Pressure',
  MAX_CUSTOM_VITAL_ERR_MSG: 'Maximum allowed custom vitals reached',
  ADD_CUSTOM_VITAL_BUTTON: '+Add Custom Vital',
  ADD_VITAL_CANCEL_BUTTON: 'Cancel',
  ADD_VITAL_SAVE_BUTTON: 'Save',
  CPM_QUICK_TIP_TITLE: 'Quick Tips',
  PARAMETER_BREACH_THRESHOLD_TOOLTIP:
    'Threshold Breached Indicator and Icon - Indicates that a set threshold has breached in the reading reported for that date and time! Be sure to view the Parameter Detailed Charts section above for more information on breached thresholds for all of the individual parameters measured.',
  PATIENT_DETAILS_TOOLTIP:
    "Basic PHI information of the patient and patient's comorbidities & medical history information.",
  MANAGE_THRESHOLD_SETTING_TOOLTIP:
    'Threshold default settings can be viewed and managed here, including the ability to customize, modify, and adjust these settings for each patient as needed. Defaults are set by your organization and should be managed by licensed medical professionals only. To modify the defaults, you must have the appropriate access rights assigned to your user account.  Please contact your administrator to get access, if required.',
  ECG_AVAILABLE_READINGS_TOOLTIP:
    'Select from the available readings to view and compare the patient’s ECG readings. By default the most recent or tagged baseline, and the last two reported ECGs are displayed. Click on one or more of the dates shown to select or de-select the ECG reading in the chart legend.  Click on multiple to compare the selected items or just view a single ECG. To view and load more ECG readings, use the manage button provided to select from a list of all the available ECG readings.',
  AVAILABLE_READING_TOOLTIP:
    'Select from the available readings to view and compare the patient’s ECG readings. Use the "Load more…" or "Change the baseline" buttons provided to select from a list of all the available readings.',
  RELATIVE_TIDAL_VOLUME_TOOLTIP:
    'Relative Tidal Volume (rTV) acts as an analogue for the standard clinical relative tidal volume measurement. Relative increasing values may indicate an increase in lung tidal volume, while relative decreasing values may indicate a decrease in lung relative tidal volume. This value is computed for both measurement positions. Please note that rTV values are not displayed in liters (L) or other standard units and values may vary between subjects.',
  RESPIRATION_TOOLTIP:
    'The number of inhalations and exhalations per unit of time, usually measured by observation of chest movements and averaging 16 to 20 per minute in a healthy adult.',
  RR_TV_TOOLTIP:
    'Respiration Rate (in breaths per minute) divided by relative tidal volume (in Ohms).  This metric computes the ratio of respiration rate and relative tidal volume. An upward trend can indicate an increase in respiration rate and/or a decrease in tidal volume, suggesting a rapid shallow breathing pattern.',
  THORACIC_IMPEDENCE_TOOLTIP:
    'Thoracic Impedance, measured in ohms (Ω), has been demonstrated to be a surrogate measurement for the fluid content in the thoracic cavity and lungs, with lower values indicating a greater hydration or fluid level and higher values meaning a patient is drier. Thoracic impedance is computed and displayed as the median impedance for each position. It is important to note that the differences in TI between different individuals can be caused by a variety of factors (i.e. body type, fat content, placement of Sensinel ™ CPM Wearable electrodes, etc.). The Sensinel ™ CPM System is not intended to directly measure thoracic fluid content.',
  S3_ENERGY_TOOLTIP:
    'Diastolic Heart Sounds Strength represents the acoustic energy level of heart sounds during diastole as measured by the Sensinel™ CPM System’s acoustic sensor. The strength of the diastolic heart sounds is then reported on the Sensinel ™ CPM Web App using the default, most-reclined body posture. In healthy subjects, this value should be stable across days, while increasing values can indicate an increase in diastolic sounds. These values are not displayed in standard units and may vary among individuals and depending on sensor placement.',
  DELTA_Z_TOOLTIP:
    ' A calculation of the difference in average thoracic impedance from one measurement position to the other. This measurement can provide insight into short-term fluid settling behavior in the thoracic cavity. Healthy patients should have ∆Z values close to 0. ∆Z is measured in ohms (Ω).',
  HEART_RATE_TOOLTIP:
    'Heart rate in beats per minute (BPM) is derived from the single-lead ECG waveform and is reported as the reciprocal of the median interval between the detected R-peaks. The heart rate for the most-reclined body posture and is the default reported value.',
  QTC_TOOLTIP:
    'QTc Interval is calculated as the difference between the onset of the QRS Width (Q-wave) and the offset of the T-wave. The offset of the T-wave is determined based on a metric representing the T-wave like morphology. QTc Interval and QRS Width are both measured in milliseconds (ms) and are calculated from the ECG waveform. The reported values are the mean values from the lowest-angle posture.',
  QRS_TOOLTIP:
    'QRS Width, measured in milliseconds (ms), represents ventricular depolarization. It is calculated as the difference between QRS Width onset and offset, which is determined based on a metric representing the QRS Width-like morphology in the single-lead ECG waveform. The reported value is the mean value from the lowest-angle posture.',
  BODY_ANGLE_TOOLTIP:
    'Body Position (Tilt Angle) is captured in degrees, with larger values (approximately 90 degrees) indicating that the patient is sitting further upright and smaller values (nearing 0 degrees) indicating that they are more reclined.',
  SKIN_TEMPERATURE_TOOLTIP:
    'This measurement offers a way to detect changes across multiple days in skin temperature on the left chest area and can provide additional information to determine whether other parameter changes are associated with a change in temperature. Values may vary with quality of attachment and ambient temperature and be lower than expected human core temperature values. The values given are an average of the highest temperature recorded during the measurement (in either position) and are provided in Fahrenheit (°F) and Celsius (°C) by the Sensinel™ CPM System.',
  TREND_CHART_TIP1:
    'Trends and ECG charts provide trended and detailed data on the actual data captured from the patient’s use of the CPM System. Daily values and grouped 3 reading averages are provided in the trend chart for the selected parameters. Individual ECG readings are also loaded and can be selected for viewing.',
  TREND_CHART_TIP2:
    'For each parameter that the Sensinel™ CPM System measures, an individual trend chart is shown. This allows you to see the trend over time and the overall characteristics of the data being captured. Select a parameter to display its trend. De-select it to hide it from the trend chart. Use your mouse to click and drag inside the chart area to pan and zoom the timeline and its data. Use your mouse to hover-over on the plot markers to see their data points and details. You can also interact with the chart using the tool menu shown above the chart on the right.',
  TREND_CHART_SELECTED_PARAMETERS:
    'Use your mouse to click and drag inside the trend chart to pan and zoom the timeline and its data. Use your mouse to hover-over on plot markers to see their data points and details. You can also interact with the chart using the tool menu shown above the chart on the right.',
  TREND_CHART_LEGEND_SELECTED_PARAMETERS:
    'Select the parameters you wish to view in the trend chart. Each reading value and grouped,  3 reading average will display for each of the parameters selected.',
  CPM_READING_TOTAL_THRESHOLD_BREACH_TOOLTIP:
    'This is the total number of breaches for all readings within the date range and time period indicated, for the current patient selected.',
  CPM_READING_TOTAL_ECG_WAVEFORM_PATTREN_TOOLTIP:
    'This is the total number of possible irregular irregularities detected for all readings within the date range and time period indicated, for the current patient selected.',
  NOT_ACCEPT_TEXT: "< Don't Accept",
  ACCEPT_TEXT: 'Accept & Continue >',
  TNC_UPDATE_TEXT:
    "We've updated our policy! Please review before <br> using the application!",
  ACCEPT_BTN: 'Accept',
  LOGOFF_BTN: 'Logoff',
  CHECK_BOX_TEXT: 'I have read the Terms and Conditions',
  CHECK_BOX_REQUIRED_ERR:
    'Please select the check box of Terms and Conditions.',
  TERMS_TITLE_TEXT: 'Terms and Conditions of Use',
  TERMS_SUCCESS_MSG: 'Accepted successfully',
  TERMS_NO_RECORD_MSG: 'No record found with the given details',
  TERMS_ERR_MSG: 'Failed to accept terms and conditions',
  TERMS_ERR_PRIVACY_TEXT:
    'By entering this site to you further acknowledge and agree to ',
  TERMS_ERR_PRIVACY_COKIES_TEXT: 'Privacy & Cookies',
  HELP_CENTRE_LABEL: 'Help Center',
  HELP_TOPIC_LABEL: 'Help Topics',
  DOWNLOAD_GUIDE_LABEL: 'Download Guides',
  SUPPORT_LINK_LABEL: 'Using the Sensinel ™ CPM Cloud',
  SUPPORT_LINK1: 'Safety and security',
  SUPPORT_LINK2: 'Your Privacy Rights and Policies',
  SUPPORT_LINK3: 'Additional Privacy Info for California Residents',
  SUPPORT_LINK4: 'Terms of service',
  SUPPORT_LINK5: 'Cookies',
  DOWNLOAD_GUIDE_LINK1: 'Sensinel Web Instruction Guide',
  DOWNLOAD_GUIDE_LINK2: 'Clinical Instruction Manual',
  DOWNLOAD_GUIDE_LINK3: 'Patient Instruction Manual',
  DOWNLOAD_GUIDE_LINK4: 'Clinician Quick Start Guide',
  DOWNLOAD_GUIDE_LINK5: 'Patient Quick Start Guide',
  HELP_CENTER_LINK1: 'Patient Video',
  HELP_CENTER_LINK2: 'Clinician Video',
  SESSION_EXPIRE_MSG_A: 'Your Session will expire in ',
  SESSION_EXPIRE_MSG_B: ' seconds!',
  GRAPH_SWEEP_SPEED_TOOLTIP:
    'The 25 mm/sec sweep speed is the speed per second while recording an ECG. This produces a perfect square with a 10 mm height by 10 mm width when using a 1 millivolt calibration signal. The ECG is displayed on a 1 mm x 1 mm block grid. This is achieved on a common laptop screen size of 15.6 diagonal inches, using a desktop resolution setting of 1920x1080 dpi, and a bit depth of 8-bit.',
  GRAPH_GAIN_TOOLTIP:
    'The Gain 10mm/mV is a standard calibration setting used when recording an ECG, where 10 mm = 1 mV. The ECG is displayed on a 1mm X 1mm block grid. This is achieved on a common laptop screen size of 15.6 diagonal inches, using a desktop resolution setting of 1920X1080 dpi, and a bit depth of 8-bit.'
};
