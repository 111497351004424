import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateDob' })
export class DateDobPipe implements PipeTransform {
  transform(date: any): any {
    if (date) {
      const dob = date.split('T')[0];
      const [yyyy, mm, dd] = dob.split(`-`);
      return `${mm}-${dd}-${yyyy}`;
    }
  }
}
