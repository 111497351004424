import { HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ITermsAndConditions } from 'src/app/interfaces/terms-and-conditions';
import { ErrorModel, StatusList } from 'src/app/model/error-model';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { RouterService } from '../../../services/router.service';
import { TermsAndConditionService } from '../../../services/terms-and-condition.service';

@Component({
  selector: 'cpm-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnDestroy {
  private observer?: IntersectionObserver;

  @Input() onClose?: (args: any) => void;
  @Input() isAcceptAllowed?: boolean;
  @ViewChild('trigger') triggerEl: ElementRef;

  tncData: ITermsAndConditions | null = null;
  isAgreed: boolean = false;
  isContentBottomReached: boolean = false;
  isTriggerElemIntersecting: boolean = false;
  isLoading = true;
  isSubmitLoading = false;

  constructor(
    private authService: AuthenticationService,
    private routerService: RouterService,
    private responseHandlerService: ResponseHandlerService,
    private termsAndConditionService: TermsAndConditionService
  ) {}

  ngAfterViewInit(): void {
    this.termsAndConditionService
      .getTermsAndCondition()
      .subscribe((res: HttpResponse<ITermsAndConditions>) => {
        if (!res.ok) {
          this.responseHandlerService.handleError(
            'An Error while fetching Terms of Use'
          );
          return;
        }

        this.tncData = res.body;
        this.isLoading = false;

        this.observer = new IntersectionObserver(
          (entries) => {
            const [trigger] = entries;

            if (trigger?.isIntersecting) {
              this.isContentBottomReached = true;
            }

            this.isTriggerElemIntersecting = Boolean(trigger?.isIntersecting);
          },
          {
            threshold: 0.1
          }
        );

        this.observer.observe(this.triggerEl.nativeElement);
      });
  }

  ngOnDestroy(): void {
    this.observer?.unobserve(this.triggerEl.nativeElement);
  }

  onContinueClick() {
    this.isSubmitLoading = true;
    this.termsAndConditionService.acceptTermsAndCondition().subscribe(
      (res: any) => {
        this.isSubmitLoading = false;
        if (res.status === StatusList.OK) {
          this.tncData.isTncAccepted = 'Y';
          this.tncData.isTncUpdated = 'N';
          this.routerService.routeTo('cpm');
        } else if (res.status === StatusList.NO_CONTENT) {
          this.authService.signOut();
        }
      },
      (err: ErrorModel) => {
        this.isSubmitLoading = false;
        this.responseHandlerService.handleError(err.errorMessage);
      }
    );
  }
}
