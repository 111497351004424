import { Component, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cpm-confirm-leave',
  templateUrl: './confirm-leave.component.html',
  styleUrls: ['./confirm-leave.component.scss']
})
export class ConfirmLeaveComponent {
  onConfirm = new EventEmitter<boolean>(); // holds event emitter reference which is emitted

  constructor(public dialogRef: MatDialogRef<ConfirmLeaveComponent>) {}

  onCancel() {
    this.dialogRef.close();
    this.onConfirm.emit(false);
  }

  onAccept() {
    this.dialogRef.close();
    this.onConfirm.emit(true);
  }
}
