export class WidgetData<T> {
  status: LoaderStatus = LoaderStatus.Loading; // status must be always present
  data: T;
  error?: string;
}

export class UpdateResponse {
  date: string;
  description: string;
  statusCode: number;
}

export enum LoaderStatus {
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
  Empty = 'empty'
}
